<template>
  <div>
    <div class="d-flex flex-column" :style="{ gap: '2rem' }">
      <div v-if="showTitle" class="d-flex align-items-center gap-2" :style="{ width: '8.125rem' }">
        <span v-if="!isSingleProduct" class="step-number">1</span>
        <p class="catalog-details">{{ $t('tasks.defineProductTask.catalogForm.details') }}</p>
      </div>
      <div class="d-flex flex-column gap-4" :style="showTitle ? {} : { marginTop: '1rem' }">
        <div
          v-if="!isSupplierView"
          class="d-flex justify-content-between align-items-center"
          :style="{ width: '26rem' }"
        >
          <p>{{ $t('tasks.defineProductTask.catalogForm.supplier') }}</p>
          <el-select
            :value="supplierName"
            size="small"
            :loading="suppliersLoading"
            :placeholder="$t('select')"
            :style="{ width: '18rem' }"
            :disabled="isSingleProduct"
            filterable
            :filter-method="handleSearchInput"
            @change="handleSupplierSelected"
          >
            <el-option
              v-for="supplierOption in suppliers"
              :key="supplierOption.id"
              :label="supplierOption.name"
              :value="supplierOption.id"
            />
          </el-select>
        </div>
        <div class="d-flex justify-content-between align-items-center" :style="{ width: '26rem' }">
          <p>{{ $t('tasks.defineProductTask.catalogForm.name') }}</p>
          <el-input
            :value="catalogName ?? existingCatalog?.name"
            size="small"
            :style="{ width: '18rem' }"
            @input="onCatalogNameChange"
          ></el-input>
        </div>
        <div class="d-flex flex-column gap-4" :style="{ width: '26rem' }">
          <div class="d-flex justify-content-between align-items-center">
            <p>{{ $t('tasks.defineProductTask.catalogForm.linkedBusinesses') }}</p>
            <MultiSelectInput
              width="18rem"
              :value="selectValue"
              :options="restaurants"
              :selected="selectedRestaurants"
              :select-all="true"
              @on-select="handleBusinessSelect"
            />
          </div>
          <div v-if="!isAllSelected" class="d-flex flex-column gap-4 restaurant-container">
            <div
              v-for="(rest, index) in selectedRestaurants"
              :key="`rest-${index}`"
              class="d-flex justify-content-end gap-2"
              :style="{ marginRight: '0.5rem', marginLeft: '0.5rem' }"
            >
              <div class="d-flex justify-content-between align-items-center" :style="{ width: '17.5rem' }">
                <p>{{ rest.name }}</p>
                <Button type="icon" @click="() => removeSelectedRestFromIndex(index)">
                  <RemoveIcon fill="#71767D" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, computed, getCurrentInstance } from 'vue';

import { RemoveIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import { useBusinessesForCatalog } from '../compositions/useBusinessesForCatalog';

import MultiSelectInput from './MultiSelectInput.vue';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';

const BUSINESSES_LIMIT = 100;
const BUSINESS_TYPES = {
  SUPPLIER: 'supplier',
  RESTAURANT: 'restaurant',
};
export default {
  name: 'CatalogDetails',
  components: { MultiSelectInput, RemoveIcon, Button },
  props: {
    supplier: { type: Object, default: null },
    isSingleProduct: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
    existingCatalog: { type: Object, default: null },
    isSupplierView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-catalog-change', 'on-supplier-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const catalogName = ref();
    const searchSupplier = ref();
    const selectedRestaurants = ref([]);
    const enteredSearch = ref(false);
    const selectedSupplier = ref();

    const { allResults: restaurantsFromBackend, isDone } = useFetchAll({
      limit: BUSINESSES_LIMIT,
      dataSelector: 'businesses',
      hook: useBusinessesForCatalog,
      variables: {
        capabilities: {
          restaurant: true,
        },
      },
    });

    const {
      businesses: suppliers,
      loading: suppliersLoading,
      clearBusinesses,
    } = useBusinessesForCatalog(
      computed(() => ({
        ...(searchSupplier.value !== null && searchSupplier.value !== '' && { search: searchSupplier.value }),
        capabilities: {
          supplier: true,
        },
        first: BUSINESSES_LIMIT,
        after: 0,
      })),
      true
    );

    watch(isDone, () => {
      if (props.existingCatalog && !props.existingCatalog.isPublic) {
        const foundRests = restaurantsFromBackend.value.filter(({ id }) =>
          props.existingCatalog.exposedToBusinesses.includes(id)
        );
        selectedRestaurants.value = foundRests;
      } else {
        selectedRestaurants.value = restaurantsFromBackend.value;
      }
    });

    watch(suppliersLoading, () => {
      if (suppliersLoading.value) {
        if (enteredSearch.value) {
          enteredSearch.value = false;
        }
      }
    });

    const handleSearchInput = (newValue) => {
      clearBusinesses();
      searchSupplier.value = newValue;
      enteredSearch.value = true;
    };

    const handleBusinessSelect = (selectedItems) => {
      selectedRestaurants.value = selectedItems;
      notifyCatalogChange();
    };

    const handleSupplierSelected = (newSupplierId) => {
      const supplier = suppliers.value.find(({ id }) => id === newSupplierId);
      selectedSupplier.value = newSupplierId;
      emit('on-supplier-change', supplier);
    };

    const isAllSelected = computed(() => selectedRestaurants.value.length === restaurantsFromBackend.value.length);
    const supplierName = computed(() => (props.isSingleProduct ? props.supplier?.name : selectedSupplier.value));
    const selectValue = computed(() => {
      if (selectedRestaurants.value.length === 0) return null;

      return isAllSelected.value
        ? root.$t('tasks.defineProductTask.catalogForm.openToEveryone')
        : root.$t('tasks.defineProductTask.catalogForm.chosen', { count: selectedRestaurants.value.length });
    });
    const searchAndSupplierLoading = computed(() => suppliersLoading.value || enteredSearch.value);

    const notifyCatalogChange = () => {
      const isPublic = isAllSelected.value;
      emit('on-catalog-change', {
        name: catalogName.value ?? props.existingCatalog?.name,
        isPublic,
        ...(isPublic ? {} : { exposedToBusinesses: selectedRestaurants.value.map(({ id }) => id) }),
      });
    };

    const removeSelectedRestFromIndex = (index) => {
      const clonedSelectedRestaurants = [...selectedRestaurants.value];
      clonedSelectedRestaurants.splice(index, 1);

      selectedRestaurants.value = clonedSelectedRestaurants;
      notifyCatalogChange();
    };

    const onCatalogNameChange = (newCatalogName) => {
      catalogName.value = newCatalogName;
      notifyCatalogChange();
    };

    return {
      BUSINESS_TYPES,
      suppliers,
      selectValue,
      catalogName,
      supplierName,
      isAllSelected,
      selectedRestaurants,
      restaurants: restaurantsFromBackend,
      suppliersLoading: searchAndSupplierLoading,
      handleSearchInput,
      onCatalogNameChange,
      handleBusinessSelect,
      handleSupplierSelected,
      removeSelectedRestFromIndex,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.catalog-details {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: $typography-primary;
}

.restaurant-container {
  max-height: 16rem;
  overflow-y: scroll;
}

.step-number {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid #d9dcde;
  background: #fff;
}
</style>
