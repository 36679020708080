<template>
  <Modal
    :visible="visible"
    :title="$t('tasks.defineProductTask.catalogForm.create')"
    :submit-text="submitText"
    :submit-loading="createLoading"
    :submit-disabled="submitDisabled"
    :custom-class="step === STEPS.PRODUCTS ? 'fixed-height' : ''"
    :cancel-text="step === STEPS.PRODUCTS ? $t('catalog.addModal.back') : null"
    :top="'17vh'"
    @on-close="onCloseModal"
    @on-submit="submitCatalog"
  >
    <template v-if="step === STEPS.PRODUCTS" #subtitle>
      <div class="d-flex gap-2 align-items-center">
        <p>{{ catalog?.name }}</p>
        <DotIcon />
        <p>{{ selectedSupplier?.name }}</p>
      </div>
    </template>
    <CatalogDetails
      v-show="step === STEPS.DETAILS"
      :supplier="supplier"
      :is-single-product="isSingleProduct"
      :is-supplier-view="isSupplierView"
      @on-catalog-change="onCatalogChange"
      @on-supplier-change="onSupplierChange"
    />
    <CatalogProducts
      v-show="step === STEPS.PRODUCTS"
      :is-add="true"
      :supplier="selectedSupplier"
      @on-selected-products="onSelectedProducts"
    />
  </Modal>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { DotIcon } from '@/assets/icons';

import CatalogDetails from './CatalogDetails.vue';
import CatalogProducts from './CatalogProducts.vue';
import Modal from './Modal.vue';
import { useCatalogCreate } from '../compositions/useCatalogCreate';

const STEPS = {
  DETAILS: 'details',
  PRODUCTS: 'products',
};
export default {
  name: 'AddCatalogModal',
  components: { Modal, DotIcon, CatalogDetails, CatalogProducts },
  props: {
    visible: { type: Boolean, default: false },
    supplier: { type: Object, default: null },
    isSingleProduct: { type: Boolean, default: true },
    isSupplierView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-close', 'on-added-catalog'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const catalog = ref();
    const step = ref(STEPS.DETAILS);
    const selectedSupplier = ref(props.isSupplierView ? props.supplier : null);
    const catalogProducts = ref();

    const submitDisabled = computed(() => {
      const missingCatalogName = !catalog.value?.name;
      const hasSupplier = !!(props.supplier || selectedSupplier.value);
      const missingExposedToBusinesses = !catalog.value?.isPublic && !catalog.value?.exposedToBusinesses?.length;

      if (missingCatalogName || !hasSupplier || missingExposedToBusinesses) {
        return true;
      }
      return false;
    });

    const submitText = computed(() => {
      if (props.isSingleProduct) {
        return root.$t('tasks.defineProductTask.catalogForm.create');
      }

      if (step.value === STEPS.PRODUCTS) {
        if (catalogProducts.value?.length) {
          return `${root.$t('tasks.defineProductTask.catalogForm.create')} (${catalogProducts.value?.length ?? 0})`;
        }

        return root.$t('tasks.defineProductTask.catalogForm.create');
      }

      return root.$t('commons.proceed');
    });

    const { createCatalog, onDone, loading: createLoading } = useCatalogCreate();

    const submitCatalog = () => {
      if (!props.isSingleProduct && step.value === STEPS.DETAILS) {
        step.value = STEPS.PRODUCTS;
      } else if (props.isSingleProduct && step.value === STEPS.DETAILS) {
        createCatalog({
          params: {
            businessId: props.isSingleProduct ? props.supplier?.id : selectedSupplier.value?.id,
            ...catalog.value,
          },
        });
      } else if (!props.isSingleProduct && step.value === STEPS.PRODUCTS) {
        createCatalog({
          params: {
            businessId: selectedSupplier.value?.id,
            ...catalog.value,
            productIds: catalogProducts.value,
          },
        });
      }
    };

    const onSelectedProducts = (newCatalogProducts) => {
      catalogProducts.value = newCatalogProducts;
    };

    const onCatalogChange = (updatedCatalog) => {
      catalog.value = updatedCatalog;
    };

    onDone(({ data: { catalogCreate } }) => {
      root.$message.success(root.$t('tasks.defineProductTask.catalogForm.createdSuccessfully'));
      emit('on-added-catalog', catalogCreate);
    });

    const onSupplierChange = (newSupplier) => {
      selectedSupplier.value = newSupplier;
    };

    const onCloseModal = () => {
      if (!props.isSingleProduct && step.value === STEPS.PRODUCTS) {
        step.value = STEPS.DETAILS;
      } else {
        emit('on-close');
      }
    };

    return {
      STEPS,
      step,
      catalog,
      submitText,
      createLoading,
      submitDisabled,
      selectedSupplier,
      onCloseModal,
      submitCatalog,
      onCatalogChange,
      onSupplierChange,
      onSelectedProducts,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .fixed-height {
    height: 700px;
  }
}
</style>
