<template>
  <div>
    <div class="d-flex flex-column" :style="{ gap: '2rem' }">
      <div v-if="showTitle" class="d-flex align-items-center gap-2">
        <span class="step-number">2</span>
        <p class="products-title">{{ $t('catalog.products.title') }}</p>
      </div>
      <div class="d-flex flex-column gap-4" :style="showTitle ? {} : { marginTop: '1rem' }">
        <div class="d-flex">
          <div :style="{ width: '14rem' }">
            <el-input
              v-model="productSearch"
              size="small"
              clearable
              prefix-icon="el-icon-search"
              :placeholder="$t('catalog.products.table.search')"
            />
          </div>
          <el-select
            v-if="!isAdd"
            class="product-type-select"
            :value="productsFilterType"
            size="small"
            :loading="loading"
            :placeholder="$t('select')"
            :style="{ width: '8rem' }"
            @change="onProductsFilterTypeChange"
          >
            <el-option
              v-for="(value, key) in PRODUCTS_FILTER_TYPE"
              :key="key"
              :label="$t(`catalog.products.filterType.${key}`)"
              :value="value"
            />
          </el-select>
        </div>
        <div class="table-container">
          <TableLoadingSkeleton v-if="loading" />
          <Table
            v-else
            border
            rounded
            :data="products"
            :columns="columns"
            :show-index="true"
            :row-selection="rowSelection"
            @on-selection-check-all="onAllRowsSelected"
            @on-select-row="onRowSelected"
          >
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, watch, toRefs } from 'vue';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useCategories } from '@/modules/product/compositions';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import { getCatalogProductsColumns } from './catalogColumns';
import { useProductsForCatalogCreation } from '../compositions/useProductsForCatalogCreation';

const PRODUCTS_FILTER_TYPE = {
  inCatalog: 'inCatalog',
  all: 'all',
};

export default {
  name: 'CatalogProducts',
  components: { Table, TableLoadingSkeleton },
  props: {
    supplier: { type: Object, default: null },
    showTitle: { type: Boolean, default: true },
    productsInCatalog: { type: Array, default: () => [] },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-selected-products'],
  setup(props, { emit }) {
    const productSearch = ref();
    const { getCategory } = useCategories();
    const columns = getCatalogProductsColumns();
    const selectedProducts = ref([]);
    const productsFilterType = ref(!props.isAdd ? PRODUCTS_FILTER_TYPE.inCatalog : PRODUCTS_FILTER_TYPE.all);

    const productsInCatalog = toRefs(props).productsInCatalog;
    const { products, loading } = useProductsForCatalogCreation(computed(() => props.supplier?.id));

    const mappedProducts = computed(() =>
      products.value
        .filter(({ name }) => {
          if (productSearch.value && productSearch.value !== '')
            return name.toLowerCase().includes(productSearch.value.toLowerCase());
          return true;
        })
        .filter(
          ({ id }) =>
            productsFilterType.value === PRODUCTS_FILTER_TYPE.all ||
            (productsFilterType.value === PRODUCTS_FILTER_TYPE.inCatalog && props.productsInCatalog?.includes(id))
        )
        .map((product) => ({
          ...product,
          category: getCategory(product) ?? MISSING_DATA_TEXT,
          sku: product.sku ?? MISSING_DATA_TEXT,
          name: product.name,
        }))
    );

    watch(
      productsInCatalog,
      (latestProductsInCatalog) => {
        selectedProducts.value = latestProductsInCatalog;
      },
      { immediate: true, deep: true }
    );

    const rowSelection = computed(() => {
      const rowSelectionIndexes = [];
      for (let index = 0; index < mappedProducts.value.length; index++) {
        const product = mappedProducts.value[index];
        if (selectedProducts.value.length && selectedProducts.value.includes(product.id)) {
          rowSelectionIndexes.push(index);
        }
      }

      return rowSelectionIndexes;
    });

    const onRowSelected = ({ rowIndex, selected }) => {
      const productIdToCheck = mappedProducts.value[rowIndex].id;
      if (selected) {
        selectedProducts.value = [...new Set([...selectedProducts.value, productIdToCheck])];
      } else {
        selectedProducts.value = selectedProducts.value.filter((id) => id !== productIdToCheck);
      }
      emit('on-selected-products', selectedProducts.value);
    };

    const onAllRowsSelected = (checked) => {
      if (checked) {
        selectedProducts.value = [
          ...new Set([...selectedProducts.value, ...mappedProducts.value.map((product) => product.id)]),
        ];
      } else {
        selectedProducts.value = [
          ...new Set(
            selectedProducts.value.filter((id) => !mappedProducts.value.map((product) => product.id).includes(id))
          ),
        ];
      }

      emit('on-selected-products');
    };

    const onProductsFilterTypeChange = (type) => {
      productsFilterType.value = type;
    };

    return {
      loading,
      columns,
      rowSelection,
      productSearch,
      products: mappedProducts,
      onRowSelected,
      onAllRowsSelected,
      productsFilterType,
      onProductsFilterTypeChange,
      PRODUCTS_FILTER_TYPE,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.products-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: $typography-primary;
}

.table-container {
  max-height: 27rem;
  overflow-y: scroll;
}

.step-number {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid #d9dcde;
  background: #fff;
}

.product-type-select {
  [dir='rtl'] & {
    margin-right: 0.5rem !important;
  }

  [dir='ltr'] & {
    margin-left: 0.5rem !important;
  }
}

::v-deep .product-type-select .el-scrollbar__wrap {
  overflow-y: hidden !important;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow-y: hidden !important;
}
</style>
